<template>
  <div class="card-block" v-if="show">
    <div class="form">
      <h1 class="title">Reset password</h1>
      <span class="info-text">Enter the email address you used when you joined and we’ll send you instructions to reset your password.</span>
      <div class="field-valid" style="margin-bottom: 9px">
        <div class="p-input-icon-left field">
          <i class="pi pi-envelope"></i>
          <InputText v-model="email" placeholder="E-mail" :class="{ 'p-invalid': errors.email }"/>
        </div>
        <span class="p-error">{{ errors.email }}</span>
      </div>

      <vue-recaptcha v-show="captchaSettings.showRecaptcha" sitekey="6Le-XrEUAAAAAFYtocclMUsnmTZ9ZG0UGec4grAR"
                     size="normal"
                     theme="light"
                     :hl="local"
                     :loading-timeout="captchaSettings.loadingTimeout"
                     @verify="recaptchaVerified"
                     @expire="recaptchaExpired"
                     ref="refCaptcha"
                     class="mb-4">
      </vue-recaptcha>
      <div style="width: 100%">
        <Button label="Submit" @click="reset" :outlined="!checkCredits" :disabled="!checkCredits" class="auth-btn"
                :class="{'active-btn': checkCredits}"/>
      </div>
    </div>
    <div class="bottom-block">
      <span>Return to</span>
      <router-link :to="{name:'auth'}">Login</router-link>
    </div>
  </div>
  <reset-message v-else/>
</template>

<script setup>
import vueRecaptcha from 'vue3-recaptcha2';
import * as yup from 'yup';
import {useForm} from 'vee-validate';
import {computed, ref} from "vue";
import i18n from "@/locales/i18n";
import {resetPasswordApi} from "@/api/auth";
import resetMessage from './resetMessage.vue'
import {useToast} from "primevue/usetoast";


const toast = useToast();


const captchaSettings = {
  showRecaptcha: true,
  loadingTimeout: 30000 // 30 seconds
}
const schema = yup.object({
  email: yup.string().required('Required field').email('Must be a valid'),
})

const {defineField, handleSubmit, errors} = useForm({
  validationSchema: schema,
});

const [email] = defineField('email');
const captcha = ref('');
const refCaptcha = ref();
const show = ref(true);

const checkCredits = computed(() => {
  if (captcha.value.length) {
    return true
  } else return false
})

const local = computed(() => {
  return i18n.global.locale
})

const validForm = handleSubmit(values => {
  return values
});

async function reset() {
  let form = await validForm()
  if (form) {
    form.captcha = captcha.value
    resetPasswordApi(form).then(() => {
      show.value = false
    }, (error) => {
      recaptchaExpired()
      toast.add({severity: 'error', detail: error.response.data.email, life: 3000});
    })
  }
}

function recaptchaVerified(response) {
  captcha.value = response;
}

function recaptchaExpired() {
  captcha.value = '';
  refCaptcha.value.reset();
}

</script>

<style scoped lang="scss">
@import url('/src/assets/styles/auth.scss');

.card-block {
  padding: 0;
}

.form {
  width: 100%;
  padding: 40px 40px 10px 40px;
  overflow-y: auto;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
  .form {
    overflow-x: hidden;
    padding: 30px 25px 75px 25px;
  }
}
</style>
