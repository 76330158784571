<template>
  <div :class="small?'tabs-menu-small': 'tabs-menu'" v-if="view">
    <div v-for="type in types" class="tab" :class="modelValue === type.id? 'active': ''"
         @click="activationType(type.id)">{{ type.name }}
    </div>
  </div>
</template>

<script>

export default {
  name: "tabsMenu",
  props: {
    modelValue: {},
    type: {},
    small: {
      default: false
    },
    types: {}
  },
  data() {
    return {

    }
  },
  mounted() {
    if(this.types) if(!this.modelValue) this.initType()
  },
  computed: {
    view() {
      if(this.types) {
        if (this.types[0].id === 'rs') {
          return false
        } else return true
      } return false
    },
  },
  methods: {
    initType() {
      this.$emit('update:modelValue', this.types[0].id)
    },
    activationType(id) {
      this.$emit('update:modelValue', id)
    }
  }
}
</script>

<style scoped lang="scss">
.tabs-menu {
  display: flex;

  .tab {
    transition: .3s;
    margin-left: -5px;
    cursor: pointer;
    z-index: 1;
    border-radius: 8px 8px 0 0;
    background: #EEF2FC;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }
  }

  .active {
    z-index: 2;
    background: #FFFFFF;
    font-weight: 600;
  }
}
.tabs-menu-small{
  display: flex;
  border: 1px solid #DDE5FB;
  border-radius: 5px;
  height: 32px;

  background: #EEF2FC;

  .tab {
    border-radius: 5px;
    transition: .3s;
    cursor: pointer;

    color: #212529;
    font-size: 14px;
    font-weight: 400;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .active {
    background: #FFFFFF;
    font-weight: 600;
  }
}
</style>
