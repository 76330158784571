import {defineStore} from 'pinia'

export const mainTypes = [
    {id: 'rs', name: 'RS'},
    {id: 'cpa', name: 'CPA'},
    {id: 'hybrid', name: 'Hybrid'}
]

export const mainStore = defineStore('main', {
    state: () => ({
        versionDisplay: '',
        status: [],
        typeProfile: [],
        rsType: null,
        translations: {},
        globalLoading: false,
        globalView: false
    }),
    getters: {},
    actions: {
        setVersionDisplay(data) {
            if (data > 767) {
                this.versionDisplay = false
            } else this.versionDisplay = true
        },
        setStatus(data) {
            this.status = data
        },
        setType(data) {
            if (data && !data.length) {
                this.typeProfile = null
                this.rsType = true
                return
            }
            let types = []
            for (let type of data) {
                const findType = mainTypes.find(t => t.id == type)
                if (findType) types.push(findType)
            }

            if (types.find(t => t.id === 'rs') && types.length > 1) {
                const index = types.findIndex(n => n.id === 'rs');
                types.splice(index, 1);
            }
            if (types[0]?.id === 'rs') {
                this.rsType = true
            } else this.rsType = false
            this.typeProfile = types
        },
        setTranslation(locale, array) {
            this.translations[locale] = array
        },
        setGlobalLoading(value) {
            this.globalLoading = value
        },
        setGlobalView(value) {
            this.globalView = value
        },
        clearType() {
            this.globalView = false
            this.typeProfile = []
            this.rsType = null
        }
    },
})
