<template>
  <div class="card-space adaptation">
    <filters @filter="filterData"/>
    <div class="hidden md:flex divider">
      <div></div>
    </div>
    <div class="hidden md:flex justify-content-end mb-3">
      <Button :label="$t('t.save_csv')" size="small" icon="pi pi-save" class="btn-gray mr-2" severity="secondary"
              @click="downloadCsv"/>
    </div>
    <div class="wrapper-table" :style="{height: heightTable}" @scroll="onScroll" ref="table">
      <DataTable :value="listData" v-model:expandedRows="expandedRows"
                 class="main-table-custom" :loading="loading" scrollHeight="flex"
                 collapsedRowIcon="pi pi-plus" expandedRowIcon="pi pi-minus">
        <template #empty>
          <div class="text-center">{{ $t('t.no_data') }}</div>
        </template>
        <Column expander style="width: 1rem"/>
        <Column field="date" :header="$t('t.field.date')"></Column>
        <Column field="profit" :header="$t('t.field.profit')"></Column>
        <Column field="orderStatus" :header="$t('t.field.status')">
          <template #body="slotProps">
            <my-chip v-if="slotProps.data.orderStatus != 0"
                     :value="listStatus[slotProps.data.orderStatus]? listStatus[slotProps.data.orderStatus]: slotProps.data.orderStatus"
                     :color="slotProps.data.orderStatus == 3?'#2970C9': '#61697B'"/>
          </template>
        </Column>
        <Column field="fdCount" :header="$t('t.field.fd_count')"></Column>
        <template #expansion="slotProps">
          <expandData :date="slotProps.data.date" :type="type" :filters="filters" :status="slotProps.data.orderStatus"/>
        </template>
      </DataTable>
    </div>

    <div class="flex md:hidden btns-top-block">
      <Button :label="$t('t.save_csv')" size="small" icon="pi pi-save" class="btn-gray mr-2 w-full"
              severity="secondary" @click="downloadCsv"/>
    </div>
  </div>
</template>

<script>

import {mainStore} from "@/store/main";
import {getReferralsApi} from "@/api/referral";
import expandData from "@/pages/Referrals/components/expandData.vue";
import cloneDeep from "clone-deep";
import MyChip from "@/components/myChip.vue";
import filters from "@/pages/Referrals/components/filters.vue";

export default {
  name: "page",
  components: {expandData, MyChip, filters},
  props: ['type'],
  data() {
    return {
      listData: [],
      expandedRows: [],
      filters: {},
      maxPage: 1,
      currentPage: 1,
      lazy: false,
      loading: false
    }
  },
  mounted() {
    if (mainStore().typeProfile) {
      this.loading = true
      this.getData()
    }
  },
  computed: {
    versionMobile() {
      return mainStore().versionDisplay
    },
    listStatus() {
      return mainStore().status
    },
    heightTable() {
      if (mainStore().rsType) {
        return this.versionMobile ? '63vh' : '66vh'
      } else {
        return this.versionMobile ? '58vh' : '66vh'
      }
    }
  },
  methods: {
    mainStore,
    onScroll(e) {
      if (!this.lazy && this.currentPage < this.maxPage) {
        if (e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 150) {
          ++this.currentPage
          this.getData()
        }
      }
    },
    filterData(filters) {
      this.loading = true
      this.filters = filters
      this.currentPage = 1
      this.getData(true)
    },
    nameStatus(id) {
      if (this.listStatus[id]) return this.listStatus[id]
      return id
    },
    downloadCsv() {
      if (!this.listData.length) return
      let cloneData = cloneDeep(this.listData)
      let headers = [this.$t('t.field.date'), this.$t('t.field.fd_count'), this.$t('t.field.status'), this.$t('t.field.profit')]
      const refinedData = []
      refinedData.push(headers)
      for (let item of cloneData) {
        delete item.users
        item.status = this.nameStatus(item.status)
        refinedData.push(Object.values(item))
      }
      let csvContent = ''
      refinedData.forEach(row => {
        csvContent += row.join(',') + '\n'
      })
      let a = document.createElement("a");
      const file = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
      a.href = URL.createObjectURL(file);
      a.download = "data.csv";
      a.click();
    },
    getData(newFilter = false) {
      this.lazy = true
      let data = {
        page: this.currentPage,
        ...this.filters
      }
      this.expandedRows = []
      getReferralsApi(data, this.type).then((resp) => {
        if (newFilter) {
          this.$refs.table.scrollTop = 0
          this.listData = []
        }
        this.listData.push(...resp.data.items)
        this.maxPage = resp.data.pagination.numPages
        this.lazy = false
        this.loading = false
      }, (error) => {
        this.loading = false
        console.log(error)
      })
    },
  }

}
</script>

<style scoped lang="scss">
.wrapper-table {
  overflow: auto;

  ::v-deep(.p-datatable) {
    height: 100%;

    .p-datatable-wrapper {
      height: 100%;
      position: relative;
      overflow: visible !important;

      .p-datatable-thead {
        top: -1px;
        z-index: 1;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .card-space {
    position: relative;
    margin-bottom: 50px;
  }

  .btns-top-block {
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    position: fixed;
    bottom: 55px;
    z-index: 3;
    padding: 10px 15px;
    left: 0;
  }
}
</style>

